import React, { Fragment } from 'react';
import * as S from './styles';

const Portrait = ({ image }) => (
  <Fragment>
    <S.MainPhoto fluid={image.childImageSharp.fluid} style={{position: 'absolute'}}/>
  </Fragment>
);

export default Portrait;
