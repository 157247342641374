import styled from 'styled-components';
import Img from 'gatsby-image';

export const Portrait = styled.div(({ theme }) => (
  {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    maxHeight: '100%',
    paddingTop: '0',
    overflow: 'hidden',
    backgroundColor: theme.secondary,

  }
));

export const MainPhoto = styled(Img)(
  {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '100%',
    height: '100%',
    transform: 'translate(-50%, -50%)',
    objectFit: 'cover',
    mixBlendMode: 'luminosity',
    opacity: .3,
  }
);
