import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { graphql } from 'gatsby';

import GlobalStyle from '../styles/globalStyles';
import theme from '../styles/theme';
import Jumbo from '../components/jumbo';
import SEO from '../components/seo';

const IndexPage = ({ pageContext: { content }, data }) => {

  return (
    <ThemeProvider theme={theme.main}>
      <Fragment>
        <SEO />
        <GlobalStyle />
        <Jumbo {...content['index']} image={data.image} header={content.header} />
      </Fragment>
    </ThemeProvider>
  );
}
export default IndexPage;

export const query = graphql`{
  image: file(relativePath: { eq: "ks.jpeg" }) {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`;