import styled from 'styled-components';
import mediaMin, { mediaMax, breakpoints } from '../../styles/mediaQueries';
import * as L from '../layout/styles';
import { H1 } from '../typography/styles';

export const JumboPage = styled(L.Page)(({ theme }) => ({
  overflow: 'hidden',
  color: theme.text,

  [mediaMax(breakpoints.md)]: {
    paddingTop: '4rem',
  },
}));

export const JumboContent = styled(L.Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  height: '100%',
}));

export const JumboText = styled(H1)(
  {
    lineHeight: '1.2',
    position: 'relative',
    zIndex: 5,
    width: '70%',
    padding: '3rem 0',

    [mediaMin(breakpoints.sm)]: {
      width: '100%',
      maxWidth: '420px',
      marginLeft: '2.2rem',
      padding: '3rem 0',
    },

    [mediaMin(breakpoints.lg)]: {
      maxWidth: '585px',
      marginLeft: '6.6rem',
      padding: '3rem 0',
    }
  },

  ({ theme }) => ({
    color: theme.primary,
  })
);

export const JumboImage = styled.div(
  {
    position: 'absolute',
    background: '#f8f8f8',
    width: '100%',
    height: '500px',
    right: '-30%',
    bottom: 'calc((100% - 500px) / 2)',
    zIndex: '20',
    mixBlendMode: 'multiply',

    [mediaMin(breakpoints.md)]: {
      width: '56%',
      minHeight: '100%',
      top: '150px',
      bottom: 'unset',
      right: '1rem',
    }
  }
);

export const JumboAnimation = styled.svg(
  {
    position: 'fixed',
    zIndex: 1,
    bottom: '-25%',
    right: '-10%',
    width: '100%',
    height: '140%',
    transform: 'scale(1.3)',

    [mediaMax(breakpoints.md)]: {
      bottom: '-85%',
      left: '-85%',
      right: '0',
      width: '200%',
      height: '200%',
    },
  },
);

export const JumboSvgPath = styled.path(
  ({ theme }) => ({ fill: theme.secondary })
);
