import React, { Component } from 'react';
import anime from 'animejs';

import Header from '../header';
import * as S from './styles';
import Pattern from '../pattern';
import Portrait from '../portrait';
import { CVLink } from '../actions';

class Jumbo extends Component {
  state = {
    paths: [
      { value: "m 791.67124,123.7059 c 20.23248,69.66785 -128.65723,115.89598 -105.65449,191.49578 23.00273,75.59977 172.58705,11.17954 213.26554,91.3957 40.67843,80.21615 27.93658,230.29086 -46.95757,293.77195 C 777.4306,763.85039 634.71449,745.06888 556.88345,669.90405 479.05241,594.73921 470.14633,433.24517 482.53399,321.7299 494.92166,210.21467 538.83605,71.197954 613.62383,41.014548 688.41161,10.831146 771.43873,54.038055 791.67124,123.7059 Z" },
      { value: "m 790.22321,150.13386 c -8.77367,107.38039 -54.07867,108.21231 -34.56731,169.86568 19.51136,61.65337 95.32452,65.99333 136.00295,140.80432 40.67843,74.81099 6.32268,173.92776 -68.57147,230.47407 C 748.19323,747.82425 565.39555,718.49244 527.64611,608.32174 489.89668,498.15104 638.61666,433.1561 593.81399,348.15246 549.01133,263.14881 449.30629,190.06742 530.35172,84.414086 611.39715,-21.239251 798.99688,42.753468 790.22321,150.13386 Z" },
      { value: "m 760.41828,125.2707 c 17.44459,62.9048 -27.92263,100.75121 -8.02551,168.92045 19.89712,68.1692 92.66114,51.31916 147.79927,119.66068 55.13813,68.34152 55.86937,179.25719 -42.36502,209.24052 C 759.59263,653.07568 744.2913,537.01683 685.27133,497.32891 626.25136,457.64099 538.50232,460.90121 489.61697,386.17563 440.73162,311.45005 458.00898,139.53804 538.6689,88.36736 619.32882,37.196678 742.97369,62.365895 760.41828,125.2707 Z" },
      { value: "m 683.4045,187.54587 c 58.1756,57.62843 132.51043,11.17461 200.97858,60.36207 68.46815,49.18746 5.87156,138.66276 2.14958,194.20646 -3.72198,55.5437 63.50044,114.81073 -33.64712,197.19804 C 755.73798,721.69975 621.48646,677.41397 565.3123,628.34572 509.13814,579.27747 451.49641,490.64839 505.048,376.85242 558.59959,263.05645 437.96203,146.21397 512.7498,99.268011 587.53757,52.322059 625.2289,129.91744 683.4045,187.54587 Z" },
      { value: "m 750.592,136.94798 c 58.1756,57.62843 65.32293,43.98711 133.79108,93.17457 68.46815,49.18746 81.00166,140.87247 77.27968,196.41617 -3.72198,55.5437 -11.62966,112.60102 -108.77722,194.98833 C 755.73798,703.91436 622.44489,659.94806 565.3123,610.56033 508.17971,561.1726 516.34548,456.09251 584.59751,383.37383 652.84954,310.65515 538.65431,241.25807 567.4373,168.98262 596.22029,96.707174 692.4164,79.319554 750.592,136.94798 Z" },
    ]
  }

  morphing = null

  svg = React.createRef();

  setAnimation = (index) => {
    this.morphing = anime({
      targets: this.svg.current,
      d: [ this.state.paths[index] ],
      easing: 'easeOutElastic',
      duration: 3000,
      elasticity: 300
    })
  }

  restartAnimation = (index) => {
    this.morphing.pause();

    this.morphing = anime({
      targets: this.svg.current,
      d: [ this.state.paths[index] ],
      easing: 'easeOutElastic',
      duration: 3000,
      elasticity: 300
    })
  }

  render() {
    const { header, image } = this.props;

    return (
      <S.JumboPage>
        <Header {...header} />

        <S.JumboContent>
          <Pattern />
          <CVLink />

          <S.JumboText>Hello! <br/> My name is Kinga, I am front-end developer and web designer.</S.JumboText>

          <S.JumboImage>
            <Portrait image={image} />
          </S.JumboImage>
        </S.JumboContent>
      </S.JumboPage>
    )
  }
}

export default Jumbo;
